import {
  Checkbox,
  ChoiceGroup,
  CommandBarButton,
  DefaultButton,
  IChoiceGroupOption,
  Icon,
  IDetailsColumnStyles,
  IDetailsListCheckboxProps,
  IIconProps,
  IStackTokens,
  Label,
  mergeStyles,
  NeutralColors,
  Selection,
  SharedColors,
  Stack,
  TextField,
} from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getConmonOrganizations, getOrganizations } from 'modules/organization/organization';
import { ATOA_CONTRIBUTOR_ID, ATOA_OWNER_ID, ATOA_READER_ID, CONMON_CONTRIBUTOR_ID } from 'modules/constants';
import { ListColumn } from '../../components/basicList/basicList';
import { BasicPane } from '../../components/basicPane/basicPane';
import { GroupedList, ListGroup } from '../../components/groupedList/groupedList';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { SaveButton } from '../../components/saving/saveButton';
import { UsersContext } from '../../components/userProvider/usersContext';
import { UserListItem } from '../../components/userProvider/userListItem';
import { AtoAOrganization, ConmonOrganization, GraphUser } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { getFormattedDate } from '../../modules/datetime/datetime';
import { getRoleOperations } from '../../modules/roles/roleOperations';
import { addUpdateUser, deleteUserRole, getUserInfo } from '../../modules/user/user';

const pageStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const columnCenteredStyle = mergeStyles({
  display: 'flex',
  width: 'fit-content',
  margin: 'auto',
});

const informationPaneStyle = mergeStyles({
  margin: '0 1em',
});

const nameHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    textAlign: 'start',
    display: 'flex',
    justifyContent: 'start',
  },
};

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
};

const actionButtontyle = mergeStyles({
  padding: '0.5em',
});

const paneDescriptionStyle = mergeStyles({
  marginTop: '3em',
  fontSize: '1.2em',
});

const panelDescriptionNameStyle = mergeStyles({
  marginTop: '2em',
  fontWeight: 'bold',
  fontSize: '1.1em',
});

const boldTextStyle = mergeStyles({
  fontWeight: 'bold',
});

const organizationDescriptionNameStyle = mergeStyles({
  fontSize: '1.1em',
});

const organizationDescriptionStyle = mergeStyles({
  marginTop: '1em',
  fontWeight: 'bold',
  fontSize: '1.1em',
});

const paneSearchDescriptionStyle = mergeStyles({
  fontSize: '1.2em',
});

const searchTextFieldStyle = mergeStyles({
  marginTop: '0.5em',
});

const buttonStyle = mergeStyles({
  maxWidth: '10%',
  color: `${NeutralColors.white}`,
  backgroundColor: `${SharedColors.cyan20}`,
});

const buttonCancelStyle = mergeStyles({
  marginLeft: '1.5em',
});

const panelFooterStyle = mergeStyles({
  position: 'absolute',
  bottom: '0px',
  paddingBottom: '1.5em',
  paddingTop: '.75rem',
  background: 'white',
  width: '100%',
});

const choiceGroupStyle = mergeStyles({
  height: '100%',
  marginTop: '2em',
  marginBottom: '3rem',
  overflowY: 'auto',
});

const panelFooterTextStyle = mergeStyles({
  alignItems: 'bottom',
  backgroundColor: '#FFF4CE',
  padding: '0.5em',
  marginBottom: '1.5em',
  fontSize: '0.9em',
});

const paneFooterStackStyle: IStackTokens = {
  childrenGap: '10%',
};

export const PermissionsPage: React.FunctionComponent = () => {
  const userContext = useContext(UsersContext);
  const [userItems, setUserItems] = useState<UserListItem[] | undefined>(undefined);
  const [groups, setGroups] = useState<ListGroup[] | undefined>(undefined);
  const [organizations, setOrganizations] = useState<AtoAOrganization[] | undefined>(undefined);
  const [conmonOrgs, setConmonOrgs] = useState<ConmonOrganization[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [selectedItem, setSelectedItem] = useState<UserListItem | undefined>(undefined);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isSavingOrDeleting, setIsSavingOrDeleting] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchItem, setSearchItem] = useState<string>();
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>();
  const [searchUser, setSearchUser] = useState<GraphUser>();
  const [roleChoice, setRoleChoice] = useState<IChoiceGroupOption>();
  const [organizationChoice, setOrganizationChoice] = useState<IChoiceGroupOption>();
  const [conmonOrganizationChoices, setConmonOrganizationChoices] = useState<string[]>([]);

  const [panelText, setPanelText] = useState<string>('');
  const addIcon: IIconProps = { iconName: 'Add' };
  const trashIcon: IIconProps = { iconName: 'Delete' };
  const searchIcon = { iconName: 'Search' };
  const addOrDeletePane = useRef<any>();
  const searchUserPane = useRef<any>();
  const assignOrganizationPane = useRef<any>();
  const assignConmonOrganizationPane = useRef<any>();

  const onRenderItemCheckbox = (props?: IDetailsListCheckboxProps) => {
    const iconStyles = { root: { fontSize: '1.6em' } };
    const checkIconStyles = mergeStyles({
      background: '#0c8ce9',
      borderRadius: '0.5em',
      color: `${NeutralColors.white}`,
    });
    return props?.checked ? (
      <Icon iconName="StatusCircleCheckmark" styles={iconStyles} className={checkIconStyles} />
    ) : (
      <Icon iconName="StatusCircleRing" styles={iconStyles} />
    );
  };

  useEffect(() => {
    userContext.requestUsers();
    getOrganizationList();
    getConmonOrganizationList();
  }, [userContext]);

  useEffect(() => {
    if (userContext.users?.length) {
      setIsLoading(LoadingState.Loading);
      groupUsers().then((users) => {
        setUserItems(users);
        setIsLoading(LoadingState.Loaded);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.users]);

  useEffect(() => {
    setIsLoading(userContext.usersLoadingState);
  }, [userContext.usersLoadingState]);

  const userColumns: ListColumn[] = [
    {
      key: 'name',
      name: 'Name',
      styles: nameHeaderStyle,
      onRender: (userItem: UserListItem) => (
        <>{userItem.user.certificateIssuer ? `[CERT] ${userItem.user.id} - ${userItem.user.name}` : userItem.user.email}</>
      ),
      minWidth: 100,
    },
    {
      key: 'date',
      name: 'Date created',
      styles: headerStyle,
      onRender: (userItem: UserListItem) => <div className={columnCenteredStyle}>{getFormattedDate(userItem.user.createdDate)}</div>,
      minWidth: 100,
    },
  ];

  const getOrganizationList = async () => {
    const organizations = await getOrganizations();
    setOrganizations(organizations);
  };

  const getConmonOrganizationList = async () => {
    const organizations = await getConmonOrganizations();
    setConmonOrgs(organizations);
  };

  const groupUsers = async () => {
    const roleGroups = await getRoleGroups();
    const userItems: UserListItem[] = [];

    // clean up users without roles or wrong roles
    // ursa and other agents create garbage data that we clean up here
    const usersCopy = userContext.users;
    usersCopy?.forEach((user) => {
      if (user.roles == null || user.roles?.length === 0) {
        userContext.users = userContext.users?.filter((obj) => obj !== user);
        return;
      }

      let roleFound = false;
      user.roles?.forEach((role) => {
        if (roleGroups.find((group) => group.key === role)) {
          roleFound = true;
        }
      });

      if (!roleFound) {
        userContext.users = userContext.users?.filter((obj) => obj !== user);
      }
    });

    // some users have multiple roles so add a line item for each role with id_role primary key
    userContext.users?.forEach((user) => {
      user.roles?.forEach((role) => {
        const groupItem = roleGroups.find((x) => x.key === role);
        userItems.push({ id: user.id.concat('_', role), role: groupItem ? groupItem.name : '', user });
        if (groupItem) {
          groupItem.count++;
        }
      });
    });

    // sort according to the roles for grouping and update the start indices
    roleGroups.sort((x, y) => x.name.localeCompare(y.name));
    userItems.sort((x, y) => x?.role.localeCompare(y.role));

    for (let i = 1; i < roleGroups.length; i++) {
      roleGroups[i].startIndex = roleGroups[i - 1].startIndex + roleGroups[i - 1].count;
    }

    setGroups(roleGroups);
    return userItems;
  };

  const getRoleGroups = async () => {
    const groups: ListGroup[] = [];
    const roles = await getRoleOperations();
    roles.forEach((role) => {
      groups.push({
        key: role.id,
        name: role.name,
        count: 0,
        isDropEnabled: false,
        startIndex: 0, // will be set later after the items are sorted
      });
    });
    return groups;
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      const item = selection.getSelection()[0];
      setSelectedItem(item as UserListItem);
    },
  });

  const onDelete = async () => {
    if (selectedItem === undefined) return;
    setIsDelete(true);
    addOrDeletePane.current.show();
  };

  const onAdd = () => {
    setIsDelete(false);
    searchUserPane.current.show();
    // set the first role choice when the search pane is shown
    setRoleChoice(getRoleOptions()[0]);
  };

  useEffect(() => {
    setPanelText(isDelete ? 'Delete' : 'Add');
  }, [isDelete]);

  const handleOnSaveOrDelete = async () => {
    // delete flow
    if (isDelete && selectedItem !== undefined) {
      setIsSavingOrDeleting(true);
      await deleteUserRole({ oid: selectedItem.user.oid, roleName: selectedItem.role }).then(() => {
        setIsSavingOrDeleting(false);
        clearChoices();
        userContext.updateUsers();
        addOrDeletePane.current.hide();
      });
    }

    // add flow
    if (!isDelete && searchUser !== undefined && roleChoice !== undefined) {
      setIsSavingOrDeleting(true);
      await addUpdateUser({
        oid: searchUser?.id,
        email: searchUser?.userPrincipalName,
        roleName: roleChoice?.key,
        organizationId: organizationChoice?.key as string,
        conmonOrganizations: conmonOrganizationChoices,
      }).then(() => {
        setIsSavingOrDeleting(false);
        clearChoices();
        userContext.updateUsers();
        addOrDeletePane.current.hide();
      });
    }
  };

  const handleSearchUser = async () => {
    if (!searchItem) return;
    setSearchErrorMessage('');
    setIsSearching(true);
    getUserInfo(searchItem).then((user) => {
      setIsSearching(false);
      setSearchItem('');
      if (user.id) {
        setSearchUser(user);
        searchUserPane.current.hide();
        if (roleChoice?.key === ATOA_OWNER_ID || roleChoice?.key === ATOA_READER_ID || roleChoice?.key === ATOA_CONTRIBUTOR_ID) {
          assignOrganizationPane.current.show();
          // set the first org choice when the search pane is shown
          setOrganizationChoice(getOrganizationOptions()[0]);
        } else if (roleChoice?.key === CONMON_CONTRIBUTOR_ID) {
          assignConmonOrganizationPane.current.show();
        } else {
          addOrDeletePane.current.show();
        }
      } else {
        setOrganizationChoice(undefined);
        setSearchErrorMessage('Invalid user');
      }
    });
  };

  const handleOrganizationAdd = () => {
    assignOrganizationPane.current.hide();
    assignConmonOrganizationPane.current.hide();
    addOrDeletePane.current.show();
  };

  const onSearchChange = useCallback((input: string | undefined) => {
    setSearchItem(input);
  }, []);

  const getRoleOptions = () => {
    const options: IChoiceGroupOption[] = [];
    groups?.forEach((group) => {
      options.push({ key: group.key, text: group.name });
    });
    return options;
  };

  const getOrganizationOptions = () => {
    const options: IChoiceGroupOption[] = [];
    organizations?.forEach((org) => {
      options.push({ key: org.id, text: org.name });
    });
    return options;
  };

  const clearChoices = () => {
    setSearchUser(undefined);
    setRoleChoice(undefined);
    setOrganizationChoice(undefined);
    setConmonOrganizationChoices([]);
  };

  const handleCancelAddOrDelete = () => {
    clearChoices();
    addOrDeletePane.current.hide();
  };

  const handleCancelSearchUser = () => {
    clearChoices();
    searchUserPane.current.hide();
  };

  const handleCancelChooseOrganization = () => {
    clearChoices();
    assignOrganizationPane.current.hide();
    assignConmonOrganizationPane.current.hide();
  };
  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={pageStyle}>
          <Stack horizontal>
            <CommandBarButton className={actionButtontyle} iconProps={addIcon} text="Add user" onClick={onAdd} />
            <CommandBarButton className={actionButtontyle} iconProps={trashIcon} text="Delete" onClick={onDelete} />
          </Stack>

          <GroupedList
            items={userItems}
            columns={userColumns}
            groups={groups}
            isCompactMode={false}
            selection={selection}
            onRenderCheckBox={onRenderItemCheckbox}
          />

          <div className={informationPaneStyle}>
            <BasicPane ref={searchUserPane} panelHeader="Add User" isPanelBlocking={false}>
              <div className={paneSearchDescriptionStyle}>Search a user by E-mail to add them to an existing role.</div>
              <div className={panelDescriptionNameStyle}>Select a user</div>
              <TextField
                className={searchTextFieldStyle}
                placeholder="Search"
                errorMessage={searchErrorMessage}
                onChange={(event, input) => onSearchChange(input)}
                iconProps={searchIcon}
              />
              <div className={choiceGroupStyle}>
                <ChoiceGroup
                  defaultSelectedKey={groups ? groups[0].key : ''}
                  options={getRoleOptions()}
                  onChange={(ev, option) => setRoleChoice(option)}
                  label="Select a role"
                />
              </div>
              <div className={panelFooterStyle}>
                <Stack horizontal tokens={paneFooterStackStyle}>
                  <SaveButton defaultText="Add" saveText="Searching" onSave={handleSearchUser} isSaving={isSearching} className={buttonStyle} />
                  <DefaultButton text="Cancel" onClick={handleCancelSearchUser} className={buttonCancelStyle} />
                </Stack>
              </div>
            </BasicPane>
            <BasicPane ref={assignOrganizationPane} panelHeader="Assign Organization" isPanelBlocking={false}>
              <div className={organizationDescriptionStyle}>Selected user:</div>
              <div className={organizationDescriptionNameStyle}>
                {`${searchUser?.givenName} ${searchUser?.surname} (${searchUser?.userPrincipalName})`}
              </div>
              <div className={choiceGroupStyle}>
                <Label>
                  <span className={boldTextStyle}>Select an organization</span>
                </Label>
                <ChoiceGroup
                  defaultSelectedKey={groups ? getOrganizationOptions()[0]?.key : ''}
                  options={getOrganizationOptions()}
                  onChange={(ev, option) => setOrganizationChoice(option)}
                />
              </div>
              <div className={panelFooterStyle}>
                <Stack horizontal tokens={paneFooterStackStyle}>
                  <SaveButton defaultText="Add" onSave={handleOrganizationAdd} className={buttonStyle} />
                  <DefaultButton
                    text="Cancel"
                    onClick={() => {
                      handleCancelChooseOrganization();
                    }}
                    className={buttonCancelStyle}
                  />
                </Stack>
              </div>
            </BasicPane>
            <BasicPane ref={assignConmonOrganizationPane} panelHeader="Assign Organization" isPanelBlocking={false}>
              <div className={organizationDescriptionStyle}>Selected user:</div>
              <div className={organizationDescriptionNameStyle}>
                {`${searchUser?.givenName} ${searchUser?.surname} (${searchUser?.userPrincipalName})`}
              </div>
              <div className={choiceGroupStyle}>
                <Label>
                  <span className={boldTextStyle}>Select one or more organizations</span>
                </Label>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '1rem' }}>
                  {conmonOrgs &&
                    conmonOrgs.map((org) => (
                      <Checkbox
                        label={org.id}
                        onChange={(ev, checked) => {
                          if (checked) {
                            setConmonOrganizationChoices((prev) => [...prev, org.id]);
                          } else {
                            setConmonOrganizationChoices((prev) => prev.filter((x) => x !== org.id));
                          }
                        }}
                      />
                    ))}
                </div>
              </div>
              <div className={panelFooterStyle}>
                <Stack horizontal tokens={paneFooterStackStyle}>
                  <SaveButton defaultText="Add" onSave={handleOrganizationAdd} className={buttonStyle} />
                  <DefaultButton
                    text="Cancel"
                    onClick={() => {
                      handleCancelChooseOrganization();
                    }}
                    className={buttonCancelStyle}
                  />
                </Stack>
              </div>
            </BasicPane>
            <BasicPane ref={addOrDeletePane} panelHeader={panelText} isPanelBlocking={false}>
              <div className={paneDescriptionStyle}>{`You are about to ${panelText.toLocaleLowerCase()}:`}</div>
              <div className={panelDescriptionNameStyle}>
                {isDelete ? selectedItem?.user.name : `${searchUser?.givenName} ${searchUser?.surname} (${searchUser?.userPrincipalName})`}
              </div>
              <div style={{ margin: '.5rem 0' }}>
                <div>Role:</div>
                <b>{` ${isDelete ? selectedItem?.role : roleChoice?.text}`}</b>
              </div>
              {!isDelete && organizationChoice ? (
                <div>
                  <div>Organization:</div>
                  <b>{` ${organizationChoice?.text}`}</b>
                </div>
              ) : (
                <div />
              )}
              <>
                {!isDelete && conmonOrganizationChoices.length > 0 && (
                  <div>
                    <div>Organizations:</div>
                    {conmonOrganizationChoices.map((org) => (
                      <div key={org}>
                        <b>{org}</b>
                      </div>
                    ))}
                  </div>
                )}
              </>
              <div className={panelFooterStyle}>
                <div className={panelFooterTextStyle}>
                  <Icon iconName="info" /> {isDelete ? 'This user will not have access once deleted.' : 'This user will have access once added'}{' '}
                </div>
                <Stack horizontal tokens={paneFooterStackStyle}>
                  <SaveButton
                    defaultText={panelText}
                    saveText={isDelete ? 'Deleting' : 'Saving'}
                    onSave={roleChoice?.key === CONMON_CONTRIBUTOR_ID ? handleOnSaveOrDelete : handleOnSaveOrDelete}
                    isSaving={isSavingOrDeleting}
                    className={buttonStyle}
                  />
                  <DefaultButton text="Cancel" onClick={handleCancelAddOrDelete} className={buttonCancelStyle} />
                </Stack>
              </div>
            </BasicPane>
          </div>
        </div>
      )}
    </>
  );
};
