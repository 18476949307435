import { DefaultButton, Dropdown, IContextualMenuItem, IDropdownOption, IDropdownStyles, Image, ImageFit, mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { sherpaBlue, menuStyles } from 'styles/contextMenu';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMtacIsAuthenticated } from 'hooks/useMtacIsAuthenticated';
import { createContextMenuItem, getMenuProps } from 'modules/contextMenu/contextMenu';
import logo from '../../assets/logo.svg';
import {
  controlCatalogsRoute,
  controlMetadataListRoute,
  metricsDashboardRoute,
  supportRoute,
  tenantGovernanceRoute,
} from '../../modules/routes/routes';
import { UserMenu } from '../userMenu/userMenu';
import { ConfigContext } from '../configProvider/configContext';
import { Hero } from '../hero/hero';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';
import { UserContext } from '../userProvider/userContext';
import { LoadingState } from '../../models/loadingState';
import { COMPLIANCE_TEAM_ACTIONS_READ, CONMON_READ, SITE_WIDE_SUBJECT, TENANT_GOVERNANCE_DASHBOARD_READ } from '../../modules/constants';
import { AuthContext } from '../authProvider/authContext';

const topBarStyles = mergeStyles({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Segoe UI',
  fontSize: '1.2em',
  color: NeutralColors.white,
});

const topBannerStyles = mergeStyles({
  backgroundColor: sherpaBlue,
  padding: '0 1em',
  display: 'flex',
});

const topNavBarStyles = mergeStyles({
  marginTop: '5px',
  padding: '0.25em 2em',
  display: 'flex',
  fontSize: '1em',
  alignItems: 'center',
  flex: 1,
});

const organizationDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    flex: 1,
    backgroundColor: sherpaBlue,
    padding: '0.25em 1em',
    maxWidth: 'fit-content',
    label: {
      margin: 'auto',
      fontFamily: 'Segoe UI',
      fontSize: '0.9em',
      color: NeutralColors.white,
    },
  },
  dropdown: {
    minWidth: '100px',
    alignItems: 'center',
  },
};

const baseLinkStyles = mergeStyles({
  color: NeutralColors.white,
  fontSize: '0.9em',
  fontWeight: '600',
  textDecoration: 'none',
});

const supportPageStyles = mergeStyles(baseLinkStyles, {
  margin: 'auto 0 auto 1.5rem',
  lineHeight: 1,
  fontFamily: 'Segoe UI',
  fontSize: '14px',
  fontWeight: '400',
});

const logoStyles = mergeStyles({
  height: '23px',
  padding: '21px 20px 17px',
});

const brandStyles = mergeStyles({
  color: NeutralColors.white,
  textDecoration: 'none',
  margin: 'auto 0',
  flexShrink: 0,
  fontFamily: 'Segoe UI',
  fontSize: '20px',
  letterSpacing: '-0.02em',
  textAlign: 'left',
});

const separatorStyle = mergeStyles({
  height: '32px',
  width: '2px',
  backgroundColor: NeutralColors.white,
  margin: 'auto 16px auto auto',
});

export interface TopNavBarProps {
  hideLoginOptions?: boolean;
}

export const TopNavBar: FunctionComponent<TopNavBarProps> = (props) => {
  const configContext = useContext(ConfigContext);
  const authContext = useContext(AuthContext);
  const heroContentContext = useContext(HeroContentContext);
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [organizationOptions, setOrganizationOptions] = useState<IDropdownOption[]>([]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const hasConMonAccess = authContext.isAuthorized([{ operation: CONMON_READ, subject: SITE_WIDE_SUBJECT }]);
  const hasTenantGovernanceAccess = authContext.isAuthorized([{ operation: TENANT_GOVERNANCE_DASHBOARD_READ, subject: SITE_WIDE_SUBJECT }]);
  const isUserAuthenticated = useMtacIsAuthenticated();

  useEffect(() => {
    userContext.requestUserInformation();
  }, [userContext]);

  useEffect(() => {
    setIsLoading(userContext.userLoadingState);
  }, [userContext.userLoadingState]);

  const catalogMenuItems: IContextualMenuItem[] = [
    createContextMenuItem('controlCatalogs', 'Control Catalogs', controlCatalogsRoute() || ''),
    createContextMenuItem('controlMetadata', 'Control Metadata', controlMetadataListRoute() || ''),
  ];

  const sspMenuItems: IContextualMenuItem[] = [
    createContextMenuItem('securityPlans', 'System Security Plans', configContext.serverConfig?.securityPlansLink || ''),
    createContextMenuItem('controlImplementations', 'Control Implementations', configContext.serverConfig?.controlImplementationsLink || ''),
  ];

  const evidenceMenuItems: IContextualMenuItem[] = useMemo(
    () => [
      createContextMenuItem('actionCenter', 'Action Center', configContext.serverConfig?.actionCenterLink || ''),
      createContextMenuItem('viewOfferings', 'View Offerings', configContext.serverConfig?.viewOfferingsLink || ''),
    ],
    [configContext.serverConfig?.actionCenterLink, configContext.serverConfig?.viewOfferingsLink],
  );

  const conMonMenuItems: IContextualMenuItem[] = useMemo(
    () => [
      createContextMenuItem('coverage', 'Coverage', '/conmon/coverage'),
      createContextMenuItem('poam', 'POA&M', '/conmon/poam'),
      createContextMenuItem('reports', 'Reports', '/conmon/reports'),
    ],
    [],
  );

  useEffect(() => {
    if (isComplianceTeam) {
      evidenceMenuItems.push(createContextMenuItem('viewServices', 'View Services', configContext.serverConfig?.viewServicesLink || ''));
      evidenceMenuItems.push(createContextMenuItem('serviceEvidence', 'Service Evidence', 'service-evidence'));
    }
  }, [isComplianceTeam, configContext.serverConfig?.viewServicesLink, evidenceMenuItems]);

  const adminMenuItems: IContextualMenuItem[] = [
    createContextMenuItem('accessManagement', 'Access Management', configContext.serverConfig?.accessManagementLink || ''),
    createContextMenuItem('requests', 'Requests', configContext.serverConfig?.requestsLink || ''),
    createContextMenuItem('metricsDashboard', 'Metrics Dashboard', metricsDashboardRoute() || ''),
  ];

  const governanceMenuItems: IContextualMenuItem[] = [createContextMenuItem('tenantGovernanceDashboard', 'Dashboard', tenantGovernanceRoute())];

  useEffect(() => {
    const organizations = userContext.organizations.map((organization) => ({
      key: organization.id ?? '',
      text: `${organization.name ?? ''}`,
    }));
    if (organizations.length > 0) {
      setOrganizationOptions(organizations);
    }
  }, [userContext, userContext.organizations]);

  const onChangeOrganization = (ev: any, newValue: any) => userContext.updateSelectedOrganization(newValue.key);

  return (
    <>
      <div className={topBarStyles} role="banner">
        <div className={topBannerStyles}>
          <Image className={logoStyles} imageFit={ImageFit.contain} src={logo} alt="Microsoft Logo" />
          <div className={separatorStyle} />
          <Link to="/" className={brandStyles}>
            Mission Trust Assurance Center
          </Link>
          {isUserAuthenticated ? (
            <div className={topNavBarStyles}>
              <DefaultButton className={menuStyles} text="Catalogs" menuProps={getMenuProps(catalogMenuItems)} />
              <DefaultButton className={menuStyles} text="SSP" menuProps={getMenuProps(sspMenuItems)} />
              <DefaultButton className={menuStyles} text="Evidence" menuProps={getMenuProps(evidenceMenuItems)} />
              {hasConMonAccess && <DefaultButton className={menuStyles} text="ConMon" menuProps={getMenuProps(conMonMenuItems)} />}
              {hasTenantGovernanceAccess && <DefaultButton className={menuStyles} text="Governance" menuProps={getMenuProps(governanceMenuItems)} />}
              <DefaultButton className={menuStyles} text="Admin" menuProps={getMenuProps(adminMenuItems)} />
              <Link className={supportPageStyles} to={supportRoute()}>
                Support
              </Link>
            </div>
          ) : (
            <></>
          )}
          {heroContentContext.getShowOrganizationDropdown() && (
            <Dropdown
              label="Organizations"
              selectedKey={userContext.selectedOrganization?.id}
              options={organizationOptions}
              styles={organizationDropdownStyles}
              disabled={isLoading !== LoadingState.Loaded}
              onChange={onChangeOrganization}
            />
          )}
          <UserMenu hideLoginOptions={props.hideLoginOptions} />
        </div>
      </div>
      <Hero />
    </>
  );
};
