import { IDialogContentProps, IStackStyles, mergeStyles, NeutralColors, Stack } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { ConfigContext } from 'components/configProvider/configContext';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { AuthContext } from '../../../components/authProvider/authContext';
import { ButtonBanner } from '../../../components/buttonBanner/buttonBanner';
import { CommentSummary } from '../../../components/commentSummary/commentSummary';
import { AuthorizationFileAttachment } from '../../../components/fileAttachment/authorizationFileAttachment';
import { FileAttachmentTable } from '../../../components/fileAttachment/fileAttachmentTable';
import { Notes } from '../../../components/notes/notes';
import { ReviewComments, ReviewCommentType } from '../../../components/reviewComments/reviewComments';
import { Agency, EvidenceComment, EvidencePackage, EvidenceUpdateType, FileAttachment, IEvidencePackage } from '../../../generated/clientApi';
import { Certification } from '../../../models/certification';
import { DocumentType } from '../../../models/documentType';
import { DomainStatus } from '../../../models/domainStatus';
import { EvidencePackageStatus } from '../../../models/evidencePackageStatus';
import { Severity } from '../../../models/severity';
import { UserRole } from '../../../models/userRole';
import {
  AUDITOR_ACTIONS_READ,
  AUTHORIZATION_ACTIONS_READ,
  DHS_AUTHORIZATION_ACTIONS_WRITE,
  DOD_AUTHORIZATION_ACTIONS_WRITE,
  EVIDENCE_PACKAGE_AUDITING_REVIEW_WRITE,
  EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_WRITE,
  EVIDENCE_PACKAGE_DISCUSSION_WRITE,
  EVIDENCE_PACKAGE_NOTES_WRITE,
  FILE_ATTACHMENT_EVIDENCE_PACKAGE_REVIEW_CREATE,
  GSA_AUTHORIZATION_ACTIONS_WRITE,
  SITE_WIDE_SUBJECT,
} from '../../../modules/constants';
import { showDialogModal } from '../../../modules/dialog/dialog';
import {
  updateEvidencePackageAuditorReview,
  updateEvidencePackageAuthorizingOfficialReview,
  updateEvidencePackageDiscussion,
} from '../../../modules/evidencePackage/evidencePackage';
import { logError } from '../../../modules/logging/logging';
import {
  chatStyles,
  CommentData,
  getAgency,
  getApproveModalBodyText,
  getCommentsData,
} from '../../../pages/evidenceDetail/menuItems/evidenceDetailReview.functions';
import { AuthorizingOfficialsDecision } from './authorizingOfficialsDecisions';

const wrapperStyle = mergeStyles({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
});

const uploadStyle = mergeStyles({
  width: '50%',
  marginBottom: '1em',
});

const modalBodyStyle = mergeStyles({
  marginBottom: '20px',
});

const approvalSectionStyles = mergeStyles({
  backgroundColor: NeutralColors.gray20,
  padding: '20px',
});

const stackStyles: Partial<IStackStyles> = { root: { width: 800, marginTop: 20 } };

export const ReviewType = {
  AUDITOR_REVIEW: 'Auditor Review',
  AUTHORIZATION_OFFICIAL_REVIEW: 'Authorizing Official Review',
};

export interface EvidenceDetailsReviewProps {
  reviewType: string;
  serviceTitle: string;
  serviceOid: string;
  validDomains: string[];
  reviewRole: UserRole.Auditor | UserRole.AuthorizingOfficial;
  fileAttachments: FileAttachment[];
  onFileAttachmentsUpdated(fileAttachments: FileAttachment[]): void;
  showNotes: boolean;
}

export const EvidenceDetailsReview: FunctionComponent<EvidenceDetailsReviewProps> = (props) => {
  const { reviewType, serviceTitle, serviceOid, validDomains, reviewRole, fileAttachments, onFileAttachmentsUpdated, showNotes } = props;
  const authContext = useContext(AuthContext);
  const evidenceContext = useContext(EvidenceContext);
  const configContext = useContext(ConfigContext);
  const [evidencePackageStatusBeingSaved, setEvidencePackageStatusBeingSaved] = useState<boolean>();
  const [currentFileAttachments, setCurrentFileAttachments] = useState<FileAttachment[]>(fileAttachments);
  const [isFileSaving, setIsFileSaving] = useState<boolean>(false);
  const [isDiscussionSaving, setIsDiscussionSaving] = useState<boolean>();
  const [isAttestationUploaded, setIsAttestationUploaded] = useState<boolean>(false);
  const [isAuthorizationMemoUploaded, setIsAuthorizationMemoUploaded] = useState<boolean>(false);
  const attestationLetterWarningMessage =
    'A document of type "Attestation Letter" is missing. This evidence package will not be forwarded to the AO until the letter is uploaded.';
  const authorizationMemoWarningMessage =
    'A document of type "Authorization Memo" is missing. This evidence package will not be forwarded to the approved state until the memo is uploaded.';
  const unanimousAOWarningMessage =
    'A unanimous decision on whether to approve or return this package is needed before it can be sent to the next step in the authorization process.';

  // role dependent logic
  const requiredDocument = reviewRole === UserRole.Auditor ? 'Attestation Letter' : 'Authorization Memo';
  const nextStageText = reviewRole === UserRole.Auditor ? 'Authorizing Official for review' : 'Approved stage';
  const approvedStatus = reviewRole === UserRole.Auditor ? EvidencePackageStatus.AoSubmit : EvidencePackageStatus.Approved;
  const returnedStatus = reviewRole === UserRole.Auditor ? EvidencePackageStatus.AuditorReturned : EvidencePackageStatus.AoReturned;

  const summaryDomainName = 'Summary';
  const decisionRowName = 'Decision';

  const showAttestationLetterWarning =
    reviewRole === UserRole.Auditor &&
    currentFileAttachments.find((fileAttachment) => fileAttachment.documentType === DocumentType.AttestationLetter) === undefined;
  const showAuthorizationMemoWarning =
    reviewRole === UserRole.AuthorizingOfficial &&
    currentFileAttachments.find((fileAttachment) => fileAttachment.documentType === DocumentType.AuthorizationMemo) === undefined;

  // If all three AO roles, do not have the same decision, show the warning
  const showUnanimousAOWarning =
    reviewRole === UserRole.AuthorizingOfficial &&
    evidenceContext.evidencePackage?.certification === Certification.FedRAMP &&
    !(
      evidenceContext.evidencePackage?.dodUserDecision?.userStatus === evidenceContext.evidencePackage?.dhsUserDecision?.userStatus &&
      evidenceContext.evidencePackage?.dodUserDecision?.userStatus === evidenceContext.evidencePackage?.gsaUserDecision?.userStatus
    );
  const disableAuditorInputs =
    evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AuditorReview ||
    !authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_AUDITING_REVIEW_WRITE, subject: SITE_WIDE_SUBJECT }]);
  const disableAOInputs =
    evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AoReview ||
    !authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_WRITE, subject: SITE_WIDE_SUBJECT }]);
  const disableInputs = evidencePackageStatusBeingSaved || isFileSaving || (reviewRole === UserRole.Auditor ? disableAuditorInputs : disableAOInputs);

  useEffect(() => {
    setCurrentFileAttachments(fileAttachments);
  }, [fileAttachments]);

  useEffect(() => {
    const attestationFileAttachment = currentFileAttachments.find((attachment) => attachment.documentType === DocumentType.AttestationLetter);
    if (attestationFileAttachment) {
      setIsAttestationUploaded(true);
    } else {
      setIsAttestationUploaded(false);
    }

    const memoFileAttachment = currentFileAttachments.find((attachment) => attachment.documentType === DocumentType.AuthorizationMemo);
    if (memoFileAttachment) {
      setIsAuthorizationMemoUploaded(true);
    } else {
      setIsAuthorizationMemoUploaded(false);
    }
  }, [currentFileAttachments]);

  const dialogInputFunction = useCallback(
    async (evidencePackageStatus: EvidencePackageStatus, agency: Agency | undefined, userComment: string | undefined) => {
      try {
        if (!evidenceContext.evidencePackage?.id) {
          return;
        }

        setEvidencePackageStatusBeingSaved(true);
        let preventStatusUpdate = false;
        let updatedEvidencePackage;

        if (reviewRole === UserRole.Auditor) {
          preventStatusUpdate = !isAttestationUploaded && evidencePackageStatus === EvidencePackageStatus.AoSubmit;
          // if there is no attestation letter and they approve anyway, do not update the status - keep it the same
          updatedEvidencePackage = await updateEvidencePackageAuditorReview({
            id: evidenceContext.evidencePackage.id,
            serviceOid,
            status: preventStatusUpdate ? evidenceContext.evidencePackage?.status : evidencePackageStatus,
            authorizationResponse: userComment,
          });
        } else {
          // Can't use ! here since agency is a number and 0 would return
          if (agency === undefined) {
            return;
          }

          preventStatusUpdate = !isAuthorizationMemoUploaded && evidencePackageStatus === EvidencePackageStatus.Approved;
          // if there is no authorization memo and they approve anyway, do not update the status - keep it the same
          updatedEvidencePackage = await updateEvidencePackageAuthorizingOfficialReview({
            id: evidenceContext.evidencePackage.id,
            serviceOid,
            status: preventStatusUpdate ? evidenceContext.evidencePackage?.status : evidencePackageStatus,
            authorizationResponse: userComment,
            agency,
            isNoteUpdate: false,
          });
        }

        evidenceContext.updateEvidencePackage(updatedEvidencePackage);
        setEvidencePackageStatusBeingSaved(false);
      } catch (error) {
        setEvidencePackageStatusBeingSaved(false);
        logError(
          `There was an issue updating the evidence package status service oid: ${serviceOid} and evidence package id: ${evidenceContext.evidencePackage?.id}`,
          error,
        );
      }
    },
    [reviewRole, evidenceContext, isAttestationUploaded, serviceOid, isAuthorizationMemoUploaded],
  );

  const displayModal = useCallback(
    (
      dialogContents: IDialogContentProps,
      bodyText: string,
      evidencePackageStatus: EvidencePackageStatus,
      agency: Agency | undefined,
      userComment: string | undefined,
    ) => {
      const modalBody = <div className={modalBodyStyle}>{bodyText}</div>;
      showDialogModal(dialogContents, () => dialogInputFunction(evidencePackageStatus, agency, userComment), modalBody, undefined, 'Submit', '600px');
    },
    [dialogInputFunction],
  );

  const onApproveButtonClick = async (agency: Agency | undefined, userComment: string | undefined) => {
    const dialogContents: IDialogContentProps = {
      title: 'Are you sure you want to approve?',
    };

    displayModal(
      dialogContents,
      getApproveModalBodyText(evidenceContext.evidencePackage, reviewRole, serviceTitle, isAttestationUploaded, configContext.serverConfig?.cloud),
      approvedStatus,
      agency,
      userComment,
    );
  };

  const onReturnButtonClick = useCallback(
    async (agency: Agency | undefined, userComment: string | undefined) => {
      const dialogContents: IDialogContentProps = {
        title: 'Are you sure you want to return?',
      };

      const bodyText = `You are about to return ${serviceTitle}. Are you sure you want to return this service at this time?`;

      displayModal(dialogContents, bodyText, returnedStatus, agency, userComment);
    },
    [displayModal, returnedStatus, serviceTitle],
  );

  const onSaveReviewNotes = async (evidenceUpdateType: EvidenceUpdateType, userComment: string) => {
    try {
      if (!evidenceContext.evidencePackage?.id) {
        return;
      }

      setEvidencePackageStatusBeingSaved(true);
      let updatedEvidencePackage;

      if (reviewRole === UserRole.Auditor) {
        // if there is no attestation letter and they approve anyway, do not update the status - keep it the same
        updatedEvidencePackage = await updateEvidencePackageAuditorReview({
          id: evidenceContext.evidencePackage.id,
          serviceOid,
          status: evidenceContext.evidencePackage.status,
          authorizationResponse: userComment,
        });
      } else {
        // Can't use ! here since agency is a number and 0 would return
        const aoUpdateType = getAgency(evidenceUpdateType);
        if (aoUpdateType === undefined) {
          return;
        }
        // if there is no authorization memo and they approve anyway, do not update the status - keep it the same
        updatedEvidencePackage = await updateEvidencePackageAuthorizingOfficialReview({
          id: evidenceContext.evidencePackage.id,
          serviceOid,
          status: evidenceContext.evidencePackage.status,
          authorizationResponse: userComment,
          agency: aoUpdateType,
          isNoteUpdate: true,
        });
      }

      evidenceContext.updateEvidencePackage(updatedEvidencePackage);
      setEvidencePackageStatusBeingSaved(false);
    } catch (error) {
      setEvidencePackageStatusBeingSaved(false);
      logError(
        `There was an issue updating the evidence package status service oid: ${serviceOid} and evidence package id: ${evidenceContext.evidencePackage?.id}`,
        error,
      );
    }
  };

  const onReviewStatusUpdate = async (domainStatus: DomainStatus, evidenceUpdateType: EvidenceUpdateType, userComment: string | undefined) => {
    const aoUpdateType = getAgency(evidenceUpdateType);

    if (domainStatus === DomainStatus.Approved) {
      await onApproveButtonClick(aoUpdateType, userComment);
    } else if (domainStatus === DomainStatus.Rejected) {
      await onReturnButtonClick(aoUpdateType, userComment);
    }
  };

  const onFileUpload = useCallback(
    (fileAttachment: FileAttachment) => {
      onFileAttachmentsUpdated([...currentFileAttachments, fileAttachment]);
    },
    [currentFileAttachments, onFileAttachmentsUpdated],
  );

  const updateIsFileSaving = useCallback((isSaving: boolean) => {
    setIsFileSaving(isSaving);
  }, []);

  const renderCommentsTable = (commentDatas: CommentData[]) => {
    if (!commentDatas?.length) {
      return <></>;
    }
    const validCommentDatas = commentDatas.filter((x: any) => [...validDomains, summaryDomainName].includes(x.domain));
    const decisionCommentData = validCommentDatas.find((x: any) => x.domain === decisionRowName);

    return (
      <>
        <CommentSummary commentData={validCommentDatas} reviewType={reviewType} />
        {decisionCommentData && <span>{decisionCommentData.domain}</span>}
      </>
    );
  };

  const onCommentSaved = (evidencePackage: IEvidencePackage) => {
    evidenceContext.updateEvidencePackage(evidencePackage as EvidencePackage);
  };

  const onDiscussionSave = useCallback(
    (inputText: string) => {
      if (!evidenceContext.evidencePackage?.id) {
        return;
      }
      setIsDiscussionSaving(true);
      const discussionComment = new EvidenceComment();
      discussionComment.comment = inputText;
      discussionComment.domain = summaryDomainName;
      discussionComment.createdDate = new Date();

      updateEvidencePackageDiscussion({
        id: evidenceContext.evidencePackage.id,
        serviceOid: evidenceContext.evidencePackage.serviceOid,
        commentType: reviewType === ReviewType.AUDITOR_REVIEW ? EvidenceUpdateType.AuditorReviewDiscussion : EvidenceUpdateType.AoReviewDiscussion,
        comment: discussionComment,
      }).then((evidencePackage: IEvidencePackage) => {
        setIsDiscussionSaving(false);
        evidenceContext.updateEvidencePackage(evidencePackage as EvidencePackage);
      });
    },
    [evidenceContext, reviewType],
  );

  const isDiscussionEnabled = () => {
    const isAuditor = authContext.isAuthorized([{ operation: AUDITOR_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
    const isAO =
      authContext.isAuthorized([{ operation: AUTHORIZATION_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]) ||
      authContext.isAuthorized([{ operation: DOD_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]) ||
      authContext.isAuthorized([{ operation: DHS_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]) ||
      authContext.isAuthorized([{ operation: GSA_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);
    const hasDiscussionWritePermission = authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_DISCUSSION_WRITE, subject: SITE_WIDE_SUBJECT }]);
    const hasNotesWritePermission = authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_NOTES_WRITE, subject: SITE_WIDE_SUBJECT }]);

    if (reviewType === ReviewType.AUTHORIZATION_OFFICIAL_REVIEW) {
      return hasNotesWritePermission || (isAO && hasDiscussionWritePermission);
    }

    return hasNotesWritePermission || (isAuditor && hasDiscussionWritePermission);
  };

  const fileUpload = () => (
    <>
      <div className={uploadStyle}>
        <h4>Upload files</h4>
        <AuthorizationFileAttachment
          serviceOid={serviceOid}
          cloud={evidenceContext.evidencePackage?.cloud || ''}
          certification={evidenceContext.evidencePackage?.certification || ''}
          disableUpload={!authContext.isAuthorized([{ operation: FILE_ATTACHMENT_EVIDENCE_PACKAGE_REVIEW_CREATE, subject: SITE_WIDE_SUBJECT }])}
          requiredAttestationEvidencePackageId={showAttestationLetterWarning ? evidenceContext.evidencePackage?.id : undefined}
          requiredMemoEvidencePackageId={showAuthorizationMemoWarning ? evidenceContext.evidencePackage?.id : undefined}
          onFileUpload={onFileUpload}
          onFileSaving={updateIsFileSaving}
        />
      </div>
      <FileAttachmentTable serviceOid={serviceOid} fileAttachments={currentFileAttachments} downloadDisabled={isFileSaving} />
    </>
  );

  return (
    <div className={wrapperStyle}>
      <h4>Summary of comments</h4>

      {renderCommentsTable(getCommentsData(evidenceContext.evidencePackage, reviewType, summaryDomainName, decisionRowName))}

      <div className={approvalSectionStyles}>
        {showAttestationLetterWarning && <ButtonBanner severity={Severity.Warning} message={attestationLetterWarningMessage} removeButton />}
        {showAuthorizationMemoWarning && <ButtonBanner severity={Severity.Warning} message={authorizationMemoWarningMessage} removeButton />}
        {showUnanimousAOWarning && <ButtonBanner severity={Severity.Warning} message={unanimousAOWarningMessage} removeButton />}
        <p>
          If this service meets all criteria for the appropriate accreditation, please upload an&nbsp;
          {requiredDocument}, input any comments, if necessary, and press the &quot;Approve&quot; button to send the service to the&nbsp;
          {nextStageText}. If there are issues that need to be addressed before this service can be accredited, please input the issues into the
          comment box and press the &quot;Return&quot; button to send the service back to the Compliance Team.
        </p>

        <Stack horizontal styles={stackStyles}>
          <ReviewComments
            reviewCommentType={reviewRole === UserRole.Auditor ? ReviewCommentType.AuditorReview : ReviewCommentType.AOReview}
            domainName={summaryDomainName}
            onSaved={onCommentSaved}
            onSaveReviewStatus={onReviewStatusUpdate}
            onSaveReviewNotes={onSaveReviewNotes}
            hideAuthorizingOfficialComments={reviewType === ReviewType.AUDITOR_REVIEW}
            hideAuditorComments={reviewType === ReviewType.AUTHORIZATION_OFFICIAL_REVIEW}
            disableAuthorizingOfficialComments={disableInputs}
            disableAuditorComments={disableInputs}
            showPivot={false}
            hideSave={false}
          />
          {reviewRole === UserRole.AuthorizingOfficial && <AuthorizingOfficialsDecision />}
        </Stack>
      </div>

      <div className={approvalSectionStyles}>{fileUpload()}</div>
      {showNotes && (
        <div className={chatStyles}>
          <Notes
            buttonText="Save"
            isSaving={isDiscussionSaving ?? false}
            disabled={!isDiscussionEnabled()}
            comments={
              reviewType === ReviewType.AUDITOR_REVIEW
                ? evidenceContext.evidencePackage?.evidenceComments?.auditorReviewDiscussion
                : evidenceContext.evidencePackage?.evidenceComments?.aoReviewDiscussion
            }
            onSave={onDiscussionSave}
            showNotes={showNotes}
            header="Chat"
          />
        </div>
      )}
    </div>
  );
};
