import { ChoiceGroup, IChoiceGroupOption, mergeStyles } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useCallback, useContext, useState } from 'react';
import { Agency, FileAttachment } from '../../generated/clientApi';
import { DocumentType } from '../../models/documentType';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { updateEvidencePackageAuditorReview, updateEvidencePackageAuthorizingOfficialReview } from '../../modules/evidencePackage/evidencePackage';
import { createEvidencePackageAttachment, createServiceAttachment } from '../../modules/fileAttachment/fileAttachment';
import { isPackageAOApproved } from './authorizationFileAttachment.functions';
import { FileAttachmentComponent, ProgressStatus } from './fileAttachment';
import { EvidenceContext } from '../../components/evidenceProvider/evidenceContext';

const choiceGroupWrapperStyle = mergeStyles({
  marginBottom: '2em',
});

const choiceGroupLabelStyle = mergeStyles({
  padding: '0 0 1em',
  minWidth: '200px',
});

interface AuthorizationFileAttachmentProps {
  serviceOid: string;
  cloud: string;
  certification: string;
  requiredAttestationEvidencePackageId: string | undefined;
  requiredMemoEvidencePackageId: string | undefined;
  disableUpload: boolean;
  onFileUpload(fileAttachment: FileAttachment): any;
  onFileSaving(saving: boolean): any;
}

const documentTypeOptions: IChoiceGroupOption[] = [
  {
    key: 'attestationLetter',
    text: DocumentType.AttestationLetter,
  },
  {
    key: 'authorizationMemo',
    text: DocumentType.AuthorizationMemo,
  },
  {
    key: 'other',
    text: DocumentType.Other,
  },
];

export const AuthorizationFileAttachment: FunctionComponent<AuthorizationFileAttachmentProps> = (props) => {
  const {
    serviceOid,
    cloud,
    certification,
    requiredAttestationEvidencePackageId,
    requiredMemoEvidencePackageId,
    disableUpload,
    onFileUpload,
    onFileSaving,
  } = props;
  const [progressStatus, setProgressStatus] = useState<ProgressStatus>();
  const [documentType, setDocumentType] = useState<string>(DocumentType.AttestationLetter);
  const evidenceContext = useContext(EvidenceContext);

  const progressStatusCallback = (loaded: number, total: number): void => {
    setProgressStatus({ loaded, total });
  };

  const onFileChange = useCallback(
    async (file: File) => {
      if (!documentType) {
        throw new Error('Document type needs to be selected before a file can be uploaded');
      }

      onFileSaving(true);
      let loadedFileAttachment;
      if (evidenceContext.evidencePackage?.id) {
        loadedFileAttachment = await createEvidencePackageAttachment(
          file,
          serviceOid,
          evidenceContext.evidencePackage.id,
          certification,
          cloud,
          documentType,
          progressStatusCallback,
        );
      } else {
        loadedFileAttachment = await createServiceAttachment(file, serviceOid, certification, cloud, documentType, false, progressStatusCallback);
      }

      // If an attestation memo is needed to advance the evidence package state, do so on upload
      if (
        evidenceContext.evidencePackage?.auditorApprovedBy &&
        requiredAttestationEvidencePackageId &&
        documentType === DocumentType.AttestationLetter
      ) {
        // Only update the status if they have approved...which we know if the "AuditorApprovedBy" field is populated (for now)
        const updatedEvidencePackage = await updateEvidencePackageAuditorReview({
          id: requiredAttestationEvidencePackageId,
          serviceOid,
          status: EvidencePackageStatus.AoSubmit,
        });

        if (updatedEvidencePackage.status === EvidencePackageStatus.AoSubmit) {
          evidenceContext.reloadEvidencePackage();
        }
      }

      // If an authorization memo is needed to advance the evidence package state, do so on upload
      if (
        evidenceContext.evidencePackage &&
        isPackageAOApproved(evidenceContext.evidencePackage) &&
        requiredMemoEvidencePackageId &&
        documentType === DocumentType.AuthorizationMemo
      ) {
        const updatedEvidencePackage = await updateEvidencePackageAuthorizingOfficialReview({
          id: requiredMemoEvidencePackageId,
          serviceOid,
          status: EvidencePackageStatus.Approved,
          agency: Agency.NA,
          isNoteUpdate: false,
        });

        if (updatedEvidencePackage.status === EvidencePackageStatus.Approved) {
          evidenceContext.reloadEvidencePackage();
        }
      }

      onFileUpload(loadedFileAttachment);
      setProgressStatus(undefined);
      onFileSaving(false);
      setDocumentType(DocumentType.AttestationLetter);
    },
    [
      certification,
      cloud,
      serviceOid,
      documentType,
      evidenceContext,
      requiredAttestationEvidencePackageId,
      requiredMemoEvidencePackageId,
      onFileUpload,
      onFileSaving,
    ],
  );

  const onDocumentTypeOptionChange = useCallback(
    (_: FormEvent<HTMLElement | HTMLInputElement> | undefined, selectedOption: IChoiceGroupOption | undefined) => {
      if (selectedOption) {
        setDocumentType(selectedOption.text);
      }
    },
    [],
  );

  const modalBody = (
    <>
      <div className={choiceGroupWrapperStyle}>
        <div className={choiceGroupLabelStyle}>Document Type</div>
        <ChoiceGroup options={documentTypeOptions} defaultSelectedKey={documentTypeOptions[0].key} onChange={onDocumentTypeOptionChange} required />
      </div>
    </>
  );

  return (
    <FileAttachmentComponent
      showModalDialog
      modalTitle="Please select which document type the uploaded file is"
      modalBody={modalBody}
      progressStatus={progressStatus}
      disableUpload={disableUpload}
      allowedExtensions={['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.tm7', '.ppt', '.pptx', '.png', '.jpg', '.visio']}
      onFileChange={onFileChange}
    />
  );
};
