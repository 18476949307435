import React, { useCallback, useState, useRef, useEffect } from 'react';
import { ProgressIndicator, mergeStyles, IDialogContentProps, CommandBarButton, IIconProps, Spinner } from '@fluentui/react';
import { megaByte } from '../../modules/conversion/conversion';
import { showDialogModal } from '../../modules/dialog/dialog';

const fileAttachmentStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const fileUtilityStyle = mergeStyles({
  display: 'flex',
});

const errorStyle = mergeStyles({
  color: 'red',
  fontSize: '.9em',
});

const statusStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '15px',
  fontSize: '.9em',
});

const statusStyleRestructured = mergeStyles({
  display: 'flex',
  paddingTop: '15px',
  fontSize: '.9em',
});

const buttonStyle = mergeStyles({
  padding: '4px 20px 6px',
  width: 'fit-content',
  height: 'fit-content',
  minWidth: '108px',
  border: '.1em solid grey',
  borderRadius: '.3em',
});

const infoStyle = mergeStyles({
  fontSize: '.9em',
  padding: '0 20px',
  height: 'fit-content',
  margin: 'auto 0',
});

const spinnerWrapperStyle = mergeStyles({
  display: 'flex',
  fontWeight: 'bold',
});

const spinnerStyle = mergeStyles({
  marginRight: '5px',
});

export interface ProgressStatus {
  loaded: number;
  total: number;
}

const uploadIcon: IIconProps = { iconName: 'Upload' };

export interface FileAttachmentProps {
  progressStatus?: ProgressStatus;
  allowedExtensions?: string[];
  allowedExtensionsText?: string;
  maxMbLength?: number;
  showModalDialog?: boolean;
  modalTitle?: string;
  modalBody?: JSX.Element;
  disableUpload?: boolean;
  display?: string;
  setLocalInputRef?: React.Dispatch<React.SetStateAction<React.RefObject<HTMLInputElement> | undefined>>;
  onFileChange(file: File | undefined): any;
}

export const FileAttachmentComponent: React.FunctionComponent<FileAttachmentProps> = (props) => {
  const {
    progressStatus,
    allowedExtensions,
    allowedExtensionsText,
    maxMbLength,
    showModalDialog,
    modalTitle,
    modalBody,
    disableUpload,
    display,
    setLocalInputRef,
    onFileChange,
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [fileUploadCompletionProgress, setFileUploadCompletionProgress] = useState<number | undefined>(undefined);
  const inputField = useRef<HTMLInputElement>(null);
  const [queuedFile, setQueuedFile] = useState<FileList | null | undefined>();

  let infoMessage = '';

  if (maxMbLength) {
    infoMessage += `Maximum file size: ${maxMbLength} MB, `;
  }

  if (allowedExtensions) {
    infoMessage += `File formats: ${allowedExtensionsText || allowedExtensions.join(', ')}`;
  }

  useEffect(() => {
    if (setLocalInputRef) {
      setLocalInputRef(inputField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputField]);

  useEffect(() => {
    if (!progressStatus) {
      setFileUploadCompletionProgress(undefined);
      setIsFileUploading(false);
      return;
    }

    if (progressStatus.total === 0) {
      return;
    }
    const percentage = progressStatus.loaded / progressStatus.total;
    setFileUploadCompletionProgress(percentage);
  }, [progressStatus]);

  const uploadFile = useCallback(
    async (files: FileList) => {
      setErrorMessage(undefined);

      // Check file size and display error message, if applicable
      if (maxMbLength && files[0].size > maxMbLength * megaByte) {
        setErrorMessage(`File must be less than ${maxMbLength} Mb`);
        return;
      }

      setIsFileUploading(true);
      try {
        // should be agnostic of the endpoint - can upload an attachment anywhere
        setFileUploadCompletionProgress(undefined);
        onFileChange(files[0]);
      } catch (e) {
        setErrorMessage('* Error: Unable to upload file');
      } finally {
        setIsFileUploading(false);
      }
    },
    [maxMbLength, onFileChange],
  );

  const dialogInputFunction = async (files: FileList | null | undefined) => {
    setQueuedFile(files);
  };

  const dialogInputDismissFunction = async () => {
    if (inputField.current) {
      inputField.current.value = '';
    }
  };

  useEffect(() => {
    if (queuedFile) {
      uploadFile(queuedFile);

      // reset the the file upload
      setQueuedFile(undefined);
      if (inputField.current) {
        inputField.current.value = '';
      }
    }
  }, [queuedFile, uploadFile]);

  const onFileChanged = useCallback(
    async (e?: React.ChangeEvent<HTMLInputElement>) => {
      const files = e?.target.files;

      if (files?.length !== 1 || files[0] === undefined) {
        return;
      }

      const dialogContents: IDialogContentProps = {
        title: modalTitle,
      };

      if (showModalDialog) {
        showDialogModal(dialogContents, () => dialogInputFunction(files), modalBody, dialogInputDismissFunction, 'Confirm');
        return;
      }

      uploadFile(files);
    },
    [modalTitle, modalBody, showModalDialog, uploadFile],
  );

  const openFileExplorerClick = () => {
    if (inputField.current) {
      inputField.current.click();
    }
  };

  const errorMessageDisplay = <div className={errorStyle}>{errorMessage}</div>;

  return (
    <div className={fileAttachmentStyle} style={{ display: display ?? 'flex' }}>
      {isFileUploading ? (
        <div className={fileUploadCompletionProgress !== undefined ? statusStyle : statusStyleRestructured}>
          <div className={spinnerWrapperStyle}>
            <Spinner className={spinnerStyle} />
            Uploading...
          </div>
          {fileUploadCompletionProgress && <ProgressIndicator percentComplete={fileUploadCompletionProgress} barHeight={5} />}
        </div>
      ) : (
        <div className={fileUtilityStyle}>
          <CommandBarButton iconProps={uploadIcon} text="Upload" className={buttonStyle} onClick={openFileExplorerClick} disabled={disableUpload} />
          <div className={infoStyle}>
            {infoMessage}
            {errorMessage && errorMessageDisplay}
          </div>
        </div>
      )}

      <input hidden ref={inputField} type="file" onChange={onFileChanged} accept={allowedExtensions?.join(',') || ''} />
    </div>
  );
};
