import { AzureStyleSettings, AzureThemeLight } from '@fluentui/azure-themes';
import { ITheme, mergeStyles, ThemeProvider, createTheme, ISemanticColors } from '@fluentui/react';
import { createBrowserHistory } from 'history';
import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { MetricsDashboard } from 'pages/metricsDashboard/metricsDashboard';
import { ConMon } from 'pages/conMon/conMon';
import { IPublicClientApplication } from '@azure/msal-browser';
import { getConfig, initializeConfig } from 'modules/config/config';
import { AuthType } from 'components/authProvider/authContext';
import { LoginSelection } from 'pages/loginSelection/loginSelection';
import { ServiceEvidencePage } from 'pages/serviceEvidence/serviceEvidence';
import { CreateEditEvidencePackagePage } from 'pages/createEditEvidencePackage/createEditEvidencePackage';
import { SystemCreate } from 'pages/systems/systemCreate';
import { EvidenceProvider } from 'components/evidenceProvider/evidenceProvider';
import { OrganizationalFrontMatterDetail } from 'pages/organizationalFrontMatterDetail/organizationalFrontMatterDetail';
import { ConMonFilterProvider } from 'pages/conMon/conMonFilterContext/conMonFilterProvider';
import { TenantGovernancePage } from 'pages/tenantGovernance/tenantGovernance';
import { TenantGovernanceDetails } from 'pages/tenantGovernance/tenantGovernanceDetails';
import { ActionCenterProvider } from './components/actionCenterProvider/actionCenterProvider';
import { AuthProvider } from './components/authProvider/authProvider';
import { ConfigProvider } from './components/configProvider/configProvider';
import { DialogModal } from './components/dialog/dialog';
import { Footer } from './components/footer/footer';
import { HeroContentProvider } from './components/heroContentProvider/heroContentProvider';
import { MessageBar } from './components/messageBar/messageBar';
import { OfferingProvider } from './components/offeringProvider/offeringProvider';
import { PageChromeHost } from './components/pageChrome/pageChrome';
import { CenteredProgressDots } from './components/progressDots/progressDots';
import { ProtectedRoute } from './components/protectedRoute/protectedRoute';
import { ServiceProvider } from './components/serviceProvider/serviceProvider';
import { TopNavBar } from './components/topNavBar/topNavBar';
import { UsersProvider } from './components/userProvider/usersProvider';
import { LoadingState } from './models/loadingState';
import { AUTH_TYPE_KEY, initializeAuth } from './modules/auth/auth';
import { getApplicationVersion, initializeApplicationInfo } from './modules/config/appInfo';
import {
  TENANT_GOVERNANCE_DASHBOARD_READ,
  COMPLIANCE_TEAM_ACTIONS_READ,
  CONTROL_METADATA_READ,
  CONTROL_READ,
  SERVICE_READ,
  SITE_WIDE_SUBJECT,
  USER_CREATE,
  USER_DELETE,
} from './modules/constants';
import { getFailedToLoadUi } from './modules/loading/loading';
import { initializeLogging, logError } from './modules/logging/logging';
import { initializeMessageBar, showError, showWarning } from './modules/messageBar/messageBar';
import { initializeUserOperations } from './modules/roles/userOperations';
import {
  accessDeniedRoute,
  accessManagementRoute,
  actionCenterRoute,
  controlEvidenceRoute,
  controlCatalogsRoute,
  landingRoute,
  offeringDetailRoute,
  offeringsRoute,
  permissionsRoute,
  organizationDetailsRoute,
  organizationsRoute,
  securityPlanManager,
  serviceDetailRoute,
  servicesRoute,
  submitOfferingRoute,
  supportRoute,
  controlCatalogRoute,
  reportsRoute,
  systemsRoute,
  controlMetadataListRoute,
  systemDetailRoute,
  systemCreateRoute,
  systemSnapshotRoute,
  metricsDashboardRoute,
  continuousMonitoringRoute,
  requestsRoute,
  serviceEvidenceRoute,
  createEvidencePackageRoute,
  evidenceDetailRoute,
  controlCatalogsLandingRoute,
  organizationFrontMatterRoute as organizationalFrontMatterRoute,
  tenantGovernanceRoute,
  tenantGovernanceDetailRoute,
} from './modules/routes/routes';
import { AccessDenied } from './pages/accessDenied/accessDenied';
import { AccessManagementPage } from './pages/accessManagement/accessManagement';
import { ActionCenterPage } from './pages/actionCenter/actionCenter';
import { ControlEvidencePage } from './pages/controlEvidence/controlEvidence';
import { LandingPage } from './pages/landing/landingPage';
import { OfferingDetailPage } from './pages/offeringDetail/offeringDetail';
import { OfferingsPage } from './pages/offerings/offerings';
import { SubmitOfferingPage } from './pages/offerings/submit-offering';
import { PermissionsPage } from './pages/permissions/permissionsPage';
import { OrganizationDetails } from './pages/organizationDetails/organizationDetails';
import { Organizations } from './pages/organizations/organizations';
import { SecurityPlanManager } from './pages/securityPlanManager/securityPlanManager';
import { ServiceDetail } from './pages/serviceDetail/serviceDetail';
import { ServicesPage } from './pages/services/services';
import { SupportPage } from './pages/support/support';
import { ControlCatalogDetail } from './pages/controlCatalogDetail/controlCatalogDetail';
import { ControlCatalogsList } from './pages/controlCatalogsList/controlCatalogsList';
import { UserProvider } from './components/userProvider/userProvider';
import { Report } from './pages/report/report';
import { SystemsPage } from './pages/systems/systems';
import { ControlMetadataLanding } from './pages/controlMetadataLanding/controlMetadataLanding';
import { SystemDetail } from './pages/systems/systemDetails';
import { Requests } from './pages/requests/requestsPage';
import { ControlCatalog } from './pages/controlCatalog/controlCatalog';

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html)': {
      height: '100%',
    },
    ':global(body)': {
      margin: 0,
      padding: 0,
      overflow: 'hidden',
    },
    ':global(#root)': {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      overflow: 'hidden',
      zIndex: 0,
    },
    ':global(.ms-Fabric--isFocusVisible input:focus + label::before)': {
      outline: '1px solid black',
    },
  },
});

const themeProviderStyle = mergeStyles({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const browserHistory = createBrowserHistory();
let didInit = false;

export const App = (): React.ReactElement => {
  const [appViewState, setAppViewState] = useState(LoadingState.NotLoaded);
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();

  useEffect(() => {
    const redirectUri = sessionStorage.getItem('redirectUri');
    if (!redirectUri) {
      sessionStorage.setItem('redirectUri', window.location.href);
    }
    const initialize = async () => {
      try {
        await initializeConfig();
        await initializeLogging();
        // init auth & pass instance to the hooks
        // only single instance to be used in classes and hooks
        setMsalInstance(await initializeAuth());

        if (getAuthType() !== AuthType.UNKNOWN) {
          await initializeUserOperations();
        }
        await initializeApplicationInfo();

        initializeMessageBar();
        const conf = getConfig();

        if (conf.dataHandlingMessage) {
          showWarning(conf.dataHandlingMessage, 0, false);
        }

        setAppViewState(LoadingState.Loaded);
      } catch (error) {
        setAppViewState(LoadingState.Error);
        logError('Configurations failed to initialize', error);
        showError('There was an issue loading the page. Please refresh and try again.');
      }
    };

    if (!didInit) {
      didInit = true;
      initialize();
    }
  }, []);

  // Theme palette auto generated from https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html
  // using '#038387' as the primary color
  const appTheme: ITheme = createTheme({
    fonts: AzureThemeLight.fonts,
    palette: {
      themePrimary: '#038387',
      themeLighterAlt: '#f0fafa',
      themeLighter: '#c7ebec',
      themeLight: '#9bd9db',
      themeTertiary: '#4bb4b7',
      themeSecondary: '#159196',
      themeDarkAlt: '#02767a',
      themeDark: '#026367',
      themeDarker: '#02494c',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralSecondaryAlt: '#8a8886',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    },
    semanticColors: {
      ButtonBorderFocus: 'rgb(96, 94, 92)',
      checkboxBackgroundChecked: '#038387',
      checkBoxCheck: 'white',
      primaryButtonBorderDisabled: 'transparent',
      controlFocus: '#038387',
    } as unknown as ISemanticColors,
  });
  appTheme.components = AzureStyleSettings(appTheme);

  const getAuthType = () => {
    const authType = localStorage.getItem(AUTH_TYPE_KEY);
    return authType || AuthType.UNKNOWN;
  };

  const getLoadedUi = () => (
    <Switch>
      <ProtectedRoute exact path={landingRoute()} claims={[]}>
        <LandingPage />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={[
          continuousMonitoringRoute,
          `${continuousMonitoringRoute}/:tab`,
          `${continuousMonitoringRoute}/:tab/:id`,
          `${continuousMonitoringRoute}/:tab/:id/:period`,
        ]}
        claims={[]}
      >
        <ConMonFilterProvider>
          <ConMon />
        </ConMonFilterProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={supportRoute()} claims={[]}>
        <SupportPage />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={accessManagementRoute()}
        claims={[
          { operation: USER_CREATE, subject: SITE_WIDE_SUBJECT },
          { operation: USER_DELETE, subject: SITE_WIDE_SUBJECT },
        ]}
      >
        <AccessManagementPage />
      </ProtectedRoute>
      <ProtectedRoute exact path={metricsDashboardRoute()} claims={[{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]}>
        <MetricsDashboard />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={permissionsRoute()}
        claims={[
          { operation: USER_CREATE, subject: SITE_WIDE_SUBJECT },
          { operation: USER_DELETE, subject: SITE_WIDE_SUBJECT },
        ]}
      >
        <UsersProvider>
          <PermissionsPage />
        </UsersProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={serviceDetailRoute()} claims={[{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]}>
        <ServiceProvider>
          <ServiceDetail />
        </ServiceProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={actionCenterRoute()} claims={[]}>
        <ActionCenterProvider>
          <ActionCenterPage />
        </ActionCenterProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={controlEvidenceRoute()} claims={[]}>
        <ActionCenterProvider>
          <ControlEvidencePage />
        </ActionCenterProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={offeringDetailRoute()} claims={[]}>
        <OfferingProvider>
          <OfferingDetailPage />
        </OfferingProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={offeringsRoute()} claims={[]}>
        <OfferingProvider>
          <OfferingsPage />
        </OfferingProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={submitOfferingRoute()} claims={[{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]}>
        <OfferingProvider>
          <SubmitOfferingPage />
        </OfferingProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={servicesRoute()} claims={[{ operation: SERVICE_READ, subject: SITE_WIDE_SUBJECT }]}>
        <ServiceProvider>
          <ServicesPage />
        </ServiceProvider>
      </ProtectedRoute>
      <ProtectedRoute exact path={serviceEvidenceRoute()} claims={[{ operation: SERVICE_READ, subject: SITE_WIDE_SUBJECT }]}>
        <ServiceEvidencePage />
      </ProtectedRoute>
      <ProtectedRoute exact path={controlCatalogsLandingRoute()} claims={[{ operation: CONTROL_READ, subject: SITE_WIDE_SUBJECT }]}>
        <ControlCatalog />
      </ProtectedRoute>
      <ProtectedRoute exact path={controlCatalogsRoute()} claims={[{ operation: CONTROL_READ, subject: SITE_WIDE_SUBJECT }]}>
        <ControlCatalogsList />
      </ProtectedRoute>
      <ProtectedRoute exact path={controlCatalogRoute()} claims={[]}>
        <ControlCatalogDetail />
      </ProtectedRoute>
      <ProtectedRoute exact path={controlMetadataListRoute()} claims={[{ operation: CONTROL_METADATA_READ, subject: SITE_WIDE_SUBJECT }]}>
        <ControlMetadataLanding />
      </ProtectedRoute>
      <ProtectedRoute exact path={securityPlanManager()} claims={[]}>
        <SecurityPlanManager />
      </ProtectedRoute>
      <ProtectedRoute exact path={organizationsRoute()} claims={[]}>
        <Organizations />
      </ProtectedRoute>
      <ProtectedRoute exact path={organizationDetailsRoute(null)} claims={[]}>
        <OrganizationDetails />
      </ProtectedRoute>
      <ProtectedRoute exact path={organizationalFrontMatterRoute()} claims={[]}>
        <OrganizationalFrontMatterDetail />
      </ProtectedRoute>
      <ProtectedRoute exact path={reportsRoute()} claims={[]}>
        <Report />
      </ProtectedRoute>
      <ProtectedRoute exact path={systemsRoute()} claims={[]}>
        <SystemsPage />
      </ProtectedRoute>
      <ProtectedRoute exact path={tenantGovernanceRoute()} claims={[{ operation: TENANT_GOVERNANCE_DASHBOARD_READ, subject: SITE_WIDE_SUBJECT }]}>
        <TenantGovernancePage />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path={tenantGovernanceDetailRoute()}
        claims={[{ operation: TENANT_GOVERNANCE_DASHBOARD_READ, subject: SITE_WIDE_SUBJECT }]}
      >
        <TenantGovernanceDetails />
      </ProtectedRoute>
      <ProtectedRoute exact path={systemCreateRoute()} claims={[]}>
        <SystemCreate />
      </ProtectedRoute>
      <ProtectedRoute exact path={systemDetailRoute()} claims={[]}>
        <SystemDetail />
      </ProtectedRoute>
      <ProtectedRoute exact path={systemSnapshotRoute()} claims={[]}>
        <SystemDetail />
      </ProtectedRoute>
      <ProtectedRoute exact path={requestsRoute()} claims={[]}>
        <Requests />
      </ProtectedRoute>
      <ProtectedRoute exact path={[createEvidencePackageRoute(), evidenceDetailRoute()]} claims={[]}>
        <ServiceProvider>
          <EvidenceProvider>
            <CreateEditEvidencePackagePage />
          </EvidenceProvider>
        </ServiceProvider>
      </ProtectedRoute>
      <Route path={accessDeniedRoute()} component={AccessDenied} />
    </Switch>
  );

  const getUiFromState = () => {
    switch (appViewState) {
      case LoadingState.Loaded:
        return getLoadedUi();
      case LoadingState.Error:
        return getFailedToLoadUi();
      case LoadingState.NotLoaded:
      case LoadingState.Loading:
      default:
        return <CenteredProgressDots />;
    }
  };

  return (
    <AuthProvider msalInstance={msalInstance as IPublicClientApplication}>
      <ThemeProvider className={themeProviderStyle} theme={appTheme}>
        <Router history={browserHistory}>
          <HeroContentProvider>
            {getAuthType() === null || getAuthType() === AuthType.UNKNOWN ? (
              <LoginSelection />
            ) : (
              <ConfigProvider>
                <UserProvider>
                  <HeroContentProvider>
                    <TopNavBar />
                    <MessageBar />
                    <DialogModal />
                    <PageChromeHost>
                      <main>{getUiFromState()}</main>
                    </PageChromeHost>
                  </HeroContentProvider>
                  <Footer applicationVersion={getApplicationVersion()} />
                </UserProvider>
              </ConfigProvider>
            )}
          </HeroContentProvider>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};
