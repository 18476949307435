import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { FileAttachment } from '../../generated/clientApi';
import { FILE_ATTACHMENT_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { getFormattedDateTime } from '../../modules/datetime/datetime';
import { downloadServiceAttachment } from '../../modules/fileAttachment/fileAttachment';
import { AuthContext } from '../authProvider/authContext';
import { BasicList, ListColumn } from '../basicList/basicList';
import { SaveButton } from '../saving/saveButton';

const nameColumnStyle = mergeStyles({
  display: 'flex',
});

interface DocumentsTabProps {
  serviceOid: string;
  fileAttachments: FileAttachment[];
  downloadDisabled: boolean;
}

export const FileAttachmentTable: FunctionComponent<DocumentsTabProps> = (props) => {
  const { serviceOid, fileAttachments, downloadDisabled } = props;
  const authContext = useContext(AuthContext);
  const [isDownloading, setIsDownloading] = useState<string>();

  const downloadButtonClick = useCallback(
    async (fileAttachment: FileAttachment) => {
      if (!fileAttachment.name) {
        return;
      }
      setIsDownloading(fileAttachment.id);
      await downloadServiceAttachment(serviceOid, fileAttachment.id, fileAttachment.name);
      setIsDownloading(undefined);
    },
    [serviceOid],
  );

  const attachmentTableColumn = useCallback(
    (
      key: string,
      name: string,
      fieldName: string,
      minWidth: number,
      ariaLabel?: string,
      onRender?: (fileAttachment: FileAttachment) => JSX.Element,
    ): ListColumn => ({
      key,
      name,
      fieldName,
      minWidth,
      ariaLabel,
      onRender,
    }),
    [],
  );

  const columns: ListColumn[] = useMemo(
    () => [
      attachmentTableColumn('name', 'Document name', 'name', 100, 'Document Name', (fileAttachment: FileAttachment) => (
        <div className={nameColumnStyle}>{fileAttachment.name}</div>
      )),
      attachmentTableColumn('cloud', 'Cloud', 'cloud', 100, 'Cloud'),
      attachmentTableColumn('authorization', 'Authorization', 'certification', 100, 'Authorization'),
      attachmentTableColumn('documentType', 'Document type', 'documentType', 150, 'Document Type'),
      attachmentTableColumn('uploadDate', 'Upload date', 'uploadDate', 200, 'Upload Date', (fileAttachment: FileAttachment) => (
        <>{fileAttachment.uploadDate && getFormattedDateTime(fileAttachment?.uploadDate)}</>
      )),
      attachmentTableColumn('downloadButton', '', '', 150, 'Download', (fileAttachment: FileAttachment) => (
        <SaveButton
          defaultText="Download"
          saveText="Downloading..."
          isSaving={isDownloading === fileAttachment.id}
          disabled={
            isDownloading !== undefined ||
            downloadDisabled ||
            !authContext.isAuthorized([{ operation: FILE_ATTACHMENT_READ, subject: SITE_WIDE_SUBJECT }])
          }
          onSave={() => downloadButtonClick(fileAttachment)}
        />
      )),
    ],
    [downloadButtonClick, isDownloading, downloadDisabled, attachmentTableColumn, authContext],
  );

  return <BasicList items={fileAttachments} columns={columns} notScrollable />;
};
